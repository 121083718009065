import { FileStatusButton, FileStatusIconProgressing, FileStatusTitle } from '@air/feature-file-status/ui';
import { ChevronDown } from '@air/next-icons';
import { memo } from 'react';

import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { DuplicateBoardTask, GetInProgressMetadata } from '~/store/tasks/types';

export type DuplicateBoardPanelInProgressPanelItemProps = {
  metadata: Pick<GetInProgressMetadata<DuplicateBoardTask>, 'sourceBoardName'>;
  onCancel: () => void;
};

export const DuplicateBoardPanelInProgressPanelItem = memo(
  ({ onCancel, metadata: { sourceBoardName } }: DuplicateBoardPanelInProgressPanelItemProps) => {
    return (
      <PaneItemTW
        avatar={<FileStatusIconProgressing />}
        title={
          <FileStatusTitle data-testid="DUPLICATING_BOARD_TITLE">{`Duplicating "${sourceBoardName}"`}</FileStatusTitle>
        }
        buttons={
          <FileStatusButton label="Hide" onClick={() => onCancel()}>
            <ChevronDown className="size-4" />
          </FileStatusButton>
        }
      />
    );
  },
);

DuplicateBoardPanelInProgressPanelItem.displayName = 'DuplicateBoardPanelInProgressPanelItem';
