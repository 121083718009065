import { FileStatusButton, FileStatusIconErrored, FileStatusTitle } from '@air/feature-file-status/ui';
import { Close } from '@air/next-icons';
import { memo } from 'react';

import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';

export type RestoreBoardFailedPanelItemProps = {
  onClear: () => void;
};

export const RestoreBoardFailedPanelItem = memo(({ onClear }: RestoreBoardFailedPanelItemProps) => {
  return (
    <PaneItemTW
      avatar={<FileStatusIconErrored />}
      title={<FileStatusTitle>Failed to recover board!</FileStatusTitle>}
      buttons={
        <FileStatusButton label="Clear" onClick={onClear}>
          <Close className="size-4" />
        </FileStatusButton>
      }
    />
  );
});

RestoreBoardFailedPanelItem.displayName = 'RestoreBoardFailedPanelItem';
