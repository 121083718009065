import { Board, Clip } from '@air/api/types';
import { getFilename } from '@air/component-file-extension-preview';
import { useMemo } from 'react';

import BoardThumbnail from '~/components/BoardThumbnail/BoardThumbnail';
import { DndDragPreviewContainer } from '~/components/DndDragPreviewContainer';
import { ClipThumbnail } from '~/components/Shared/ClipList/ClipItem/ClipThumbail';
import DragType, { DraggingItems } from '~/components/Shared/Drag/dragTypes';
import { ClipIconSize } from '~/types/clip-icon-size';

import { ReactDndDragItemsCount } from './shared/ReactDndDragItemsCount';

interface TableItemsDragPreviewProps {
  items: DraggingItems<Board | Clip>;
}

const PREVIEW_IMAGE_SIZE = 40;

export const TableItemsDragPreview = ({
  items: { type, count, firstItem, mixedTypes },
}: TableItemsDragPreviewProps) => {
  const itemTitle = useMemo(() => {
    switch (type) {
      case DragType.boardNavFavorites:
      case DragType.boardNavWorkspace:
      case DragType.boardNavLibrary:
      case DragType.boardCard:
        return (firstItem as Board).title;
      case DragType.asset: {
        return firstItem.title || (firstItem as Clip).importedName || '';
      }
      case DragType.file: {
        return getFilename(firstItem as Clip);
      }
    }
  }, [firstItem, type]);

  const itemImage = useMemo(() => {
    switch (type) {
      case DragType.boardNavFavorites:
      case DragType.boardNavWorkspace:
      case DragType.boardCard: {
        const board = firstItem as Board;
        return <BoardThumbnail board={board} sizes="40px" width={PREVIEW_IMAGE_SIZE} height={PREVIEW_IMAGE_SIZE} />;
      }
      case DragType.asset: {
        const asset = firstItem as Clip;
        return (
          <ClipThumbnail
            {...asset}
            src={asset.assets.image}
            iconSize={ClipIconSize.small}
            borderRadius={4}
            hideText={true}
            height={PREVIEW_IMAGE_SIZE}
            width={PREVIEW_IMAGE_SIZE}
            size="small"
          />
        );
      }
      case DragType.file: {
        const file = firstItem as Clip;
        return (
          <ClipThumbnail {...file} hideText={true} src="" iconSize={ClipIconSize.small} borderRadius={4} size="small" />
        );
      }
    }
  }, [type, firstItem]);

  const positionOffset = count > 1 ? 6 : 0;

  return (
    <div className="relative">
      {/*Height must be set in style, because it is set in style props in DndDragPreviewContainer*/}
      {count > 2 && (
        <DndDragPreviewContainer className="absolute left-4" style={{ top: 16 + positionOffset, height: 64 }} />
      )}
      {count > 1 && (
        <DndDragPreviewContainer className="absolute left-2.5" style={{ top: 10 + positionOffset, height: 64 }} />
      )}
      <DndDragPreviewContainer
        className="absolute left-1 flex items-center p-3"
        style={{ top: 4 + positionOffset, height: 64 }}
      >
        <div
          className="relative"
          style={{
            minWidth: PREVIEW_IMAGE_SIZE,
            minHeight: PREVIEW_IMAGE_SIZE,
            width: PREVIEW_IMAGE_SIZE,
            height: PREVIEW_IMAGE_SIZE,
          }}
        >
          {itemImage}
        </div>
        <div className="ml-3 truncate text-16 font-medium text-grey-12">{itemTitle}</div>
      </DndDragPreviewContainer>
      {count > 1 && (
        <div className="absolute left-1 top-[-18px]">
          <ReactDndDragItemsCount type={type} mixedTypes={mixedTypes} count={count} />
        </div>
      )}
    </div>
  );
};
