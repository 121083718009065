import { FileStatusButton, FileStatusIconErrored, FileStatusTitle } from '@air/feature-file-status/ui';
import { Close } from '@air/next-icons';
import { memo } from 'react';

import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';

export type RestoreAssetTaskFailedPanelItemProps = {
  onClear: () => void;
};

export const RestoreAssetTaskFailedPanelItem = memo(({ onClear }: RestoreAssetTaskFailedPanelItemProps) => {
  return (
    <PaneItemTW
      avatar={<FileStatusIconErrored />}
      title={<FileStatusTitle>Failed to recover asset!</FileStatusTitle>}
      buttons={
        <FileStatusButton label="Clear" onClick={onClear}>
          <Close className="size-4" />
        </FileStatusButton>
      }
    />
  );
});

RestoreAssetTaskFailedPanelItem.displayName = 'RestoreAssetTaskFailedPanelItem';
