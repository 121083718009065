import {
  FileStatusButton,
  FileStatusIconCompleted,
  FileStatusSubtitle,
  FileStatusTitle,
} from '@air/feature-file-status/ui';
import { Close } from '@air/next-icons';
import Link from 'next/link';
import pluralize from 'pluralize';
import { memo } from 'react';

import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { Routes } from '~/constants/routes';
import { useLaunchDarklyFlags } from '~/hooks/useLaunchDarklyFlags';
import { BoardDeletionTask, GetCompletedMetadata } from '~/store/tasks/types';

export type DeleteBoardCompletedPanelItemProps = {
  metadata: GetCompletedMetadata<BoardDeletionTask>;
  onClear: () => void;
};

export const DeleteBoardCompletedPanelItem = memo(
  ({ metadata: { boardIds }, onClear }: DeleteBoardCompletedPanelItemProps) => {
    const { deletionRecovery } = useLaunchDarklyFlags();

    return (
      <PaneItemTW
        truncate={false}
        avatar={<FileStatusIconCompleted />}
        title={<FileStatusTitle>Deleted successfully</FileStatusTitle>}
        subtitle={
          deletionRecovery ? (
            <FileStatusSubtitle className="truncate">
              {pluralize('Board', boardIds.length, true)} moved to{' '}
              <Link href={Routes.media.recentlyDeleted} className="cursor-pointer text-14 text-blue-7">
                Recently deleted
              </Link>
            </FileStatusSubtitle>
          ) : (
            <FileStatusSubtitle className="truncate">
              {`${pluralize('board', boardIds.length, true)} deleted`}
            </FileStatusSubtitle>
          )
        }
        buttons={
          <FileStatusButton label="Clear" onClick={onClear}>
            <Close className="size-4" />
          </FileStatusButton>
        }
      />
    );
  },
);

DeleteBoardCompletedPanelItem.displayName = 'DeleteBoardCompletedPanelItem';
