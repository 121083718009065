import { useTrackClickedToOpenImportFromDeviceModal, useTrackInitiateUpload } from '@air/analytics';
import { createContext, memo, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DropEvent, FileRejection, FileWithPath, useDropzone } from 'react-dropzone';

import { DropzoneOverlay } from './DropzoneOverlay';

export const UPLOAD_INPUT = 'UPLOAD_INPUT';

export type InputFile = FileWithPath;

export type MutableInputFile = InputFile & { path?: string };

export type UploadInputType = 'files' | 'folders';

export type UploadContextType = {
  openUploader: (params?: { type?: UploadInputType }) => void;
};

export const UploadContext = createContext<UploadContextType>({
  openUploader: () => {
    console.error('UploadContext is not provided');
  },
});

export type OnUploadParams = {
  acceptedFiles: InputFile[];
  rejectedFiles: InputFile[];
  event: DropEvent | null;
};

export type UploadDropzoneProps = {
  onUpload: (params: OnUploadParams) => void;
  disabled?: boolean;
  children?: ReactNode;
};

export const UploadDropzone = memo(({ onUpload, children, disabled = false }: UploadDropzoneProps) => {
  const { trackInitiateUpload } = useTrackInitiateUpload();
  const { trackClickedToOpenImportFromDeviceModal } = useTrackClickedToOpenImportFromDeviceModal();

  const [inputType, setInputType] = useState<UploadInputType | null>(null);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: (acceptedFiles: InputFile[], fileRejections: FileRejection[], event: DropEvent | null) => {
      setInputType(null);

      onUpload({
        acceptedFiles: acceptedFiles as InputFile[],
        rejectedFiles: fileRejections.map(({ file }) => file) as InputFile[],
        event,
      });

      // TODO: Consider adding analytics event for when files are selected for importing
      const eventType = event?.type === 'drop' ? 'drag-drop' : event?.type === 'change' ? 'new-button' : undefined;
      if (!!eventType) {
        trackInitiateUpload({ location: eventType });
      }
    },
    noKeyboard: true,
    onFileDialogCancel: () => setInputType(null),
    disabled,
    noClick: true,
    useFsAccessApi: false,
  });

  const openRef = useRef(open);
  openRef.current = open;

  // Only open the uploader when the inputType changes
  useEffect(() => {
    if (inputType) {
      openRef.current();
    }
  }, [inputType]);

  const openUploader = useCallback(
    (params?: { type?: UploadInputType }) => {
      // Uploading works, but on ios devices, a runtime error is shown on local build:
      // Error: NotAllowedError: The request is not allowed by the user agent or the platform in the current context
      trackClickedToOpenImportFromDeviceModal();
      setInputType(params?.type ?? 'files');
    },
    [trackClickedToOpenImportFromDeviceModal],
  );

  const contextValue = useMemo(
    () => ({
      openUploader,
    }),
    [openUploader],
  );

  return (
    <div className="relative h-full" {...getRootProps()}>
      {inputType === 'folders' ? (
        <input
          data-testid={UPLOAD_INPUT}
          {...getInputProps()}
          // @ts-ignore - https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement/webkitdirectory - "directory" is a nonstandard prop but is the only way to do it 💁
          webkitdirectory=""
          mozdirectory=""
          directory=""
          type="file"
        />
      ) : (
        <input data-testid={UPLOAD_INPUT} {...getInputProps()} />
      )}
      {isDragActive && <DropzoneOverlay />}
      <UploadContext.Provider value={contextValue}>{children}</UploadContext.Provider>
    </div>
  );
});

UploadDropzone.displayName = 'UploadDropzone';
