import { FileTrackingItem } from '@air/feature-file-status/ui';
import { Upload, UploadProgressInfo, UploadStatus } from '@air/redux-uploader';

import { UploadPreviewLoader } from '../ui/UploadPreviewLoader';

export const convertUploadToFileTrackingPaneItem = (
  upload: Upload,
  progressInfo?: UploadProgressInfo,
): FileTrackingItem => {
  return {
    id: upload.id,
    status: upload.status,
    error: upload.status === UploadStatus.failed ? (upload.errorMessage ? upload.errorMessage : 'Failed') : '',
    ext: upload.apiUploadData.ext,
    title: upload.apiUploadData.importedName,
    sizeInBytes: upload.apiUploadData.size || 0,
    thumbnailSource: upload.imageBase64,
    currentBytesUploaded: progressInfo?.progress || 0,
    previewLoader: <UploadPreviewLoader upload={upload} />,
  };
};
