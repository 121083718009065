import {
  FileStatusButton,
  FileStatusIconCompleted,
  FileStatusSubtitle,
  FileStatusTitle,
} from '@air/feature-file-status/ui';
import { Close } from '@air/next-icons';
import Link from 'next/link';
import { memo } from 'react';

import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { Routes } from '~/constants/routes';
import { useLaunchDarklyFlags } from '~/hooks/useLaunchDarklyFlags';
import { GetCompletedMetadata, LibraryDeletionTask } from '~/store/tasks/types';

export type DuplicateBoardCompletedPanelItemProps = {
  metadata: GetCompletedMetadata<LibraryDeletionTask>;
  onClear: () => void;
};

export const DeleteLibraryCompletedPanelItem = memo(({ onClear }: DuplicateBoardCompletedPanelItemProps) => {
  const { deletionRecovery } = useLaunchDarklyFlags();

  return (
    <PaneItemTW
      truncate={false}
      avatar={<FileStatusIconCompleted />}
      title={<FileStatusTitle>Deleted successfully</FileStatusTitle>}
      subtitle={
        deletionRecovery ? (
          <FileStatusSubtitle className="truncate">
            Library moved to{' '}
            <Link href={Routes.media.recentlyDeleted} className="cursor-pointer text-14 text-blue-7">
              Recently deleted
            </Link>
          </FileStatusSubtitle>
        ) : (
          <FileStatusSubtitle className="truncate">Library deleted</FileStatusSubtitle>
        )
      }
      buttons={
        <FileStatusButton label="Clear" onClick={onClear}>
          <Close className="size-4" />
        </FileStatusButton>
      }
    />
  );
});

DeleteLibraryCompletedPanelItem.displayName = 'DeleteLibraryCompletedPanelItem';
