import { useTrackCompletedDragging } from '@air/analytics';
import { Board } from '@air/api/types';
import { isAirror } from '@air/errors';
import { useAirModal } from '@air/provider-modal';
import { useToasts } from '@air/provider-toast';
import { resetSelectedItemsAction, selectedItemsCountSelector } from '@air/redux-selected-items';
import pluralize from 'pluralize';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { MoveItemsConfirmationModal } from '~/components/Modals/MoveItemsConfirmationModal/MoveItemsConfirmationModal';
import { ToastLink } from '~/components/UI/ToastLink';
import { useGoToBoardPage } from '~/hooks/useGoToBoardPage';
import { useItemParentChangeType } from '~/hooks/useItemParentChangeType';
import {
  SelectableGalleryAssetItem,
  SelectableGalleryBoardItem,
  SelectableGalleryFileItem,
} from '~/store/selectedItems/types';
import { useAddAssetsToBoards } from '~/swr-hooks/boards/useAddAssetsToBoards';
import { useMoveBoards } from '~/swr-hooks/boards/useMoveBoards';
import { useMoveAssetsToBoard } from '~/swr-hooks/clips/useMoveAssetsToBoard';
import { getBoardIdFromPath } from '~/utils/PathUtils';
import { useAirStore } from '~/utils/ReduxUtils';

import { DropLocation } from '../../dragTypes';

export interface HandleDragAssetsToMergeParams {
  board: Board;
  boards?: SelectableGalleryBoardItem<Board>[];
  assets?: (SelectableGalleryAssetItem | SelectableGalleryFileItem)[];
  dropLocation: DropLocation;
}

export const useHandleDragItemsToBoard = () => {
  const store = useAirStore();
  const dispatch = useDispatch();
  const { showToast } = useToasts();
  const { trackCompletedDragging } = useTrackCompletedDragging();
  const { goToBoardPage } = useGoToBoardPage();
  const {
    moveAssetsToBoard: { mutateAsync: moveAssetsToBoardAsync },
  } = useMoveAssetsToBoard();
  const {
    moveBoards: { mutateAsync: moveBoardsAsync },
  } = useMoveBoards();
  const addAssetsToBoardMutation = useAddAssetsToBoards();
  const { getParentChangeType } = useItemParentChangeType();
  const [showMoveItemsConfirmationModal] = useAirModal(MoveItemsConfirmationModal);

  /**
   * Handles the logic for moving/adding assets to a board.
   * It will return 'moved' if the assets were moved successfully.
   * It will return 'added' if the assets were added to the board
   * or will return false if no-op.
   */
  const handleDragAssetsToBoard = useCallback(
    async ({
      assets = [],
      clipIds,
      hasComments,
      board,
      shouldMoveItemsInsteadOfAdd,
    }: Pick<HandleDragAssetsToMergeParams, 'assets' | 'board'> & {
      clipIds: string[];
      hasComments: boolean;
      shouldMoveItemsInsteadOfAdd: boolean;
    }) => {
      if (assets.length === 0) return false;

      const currentBoardId = getBoardIdFromPath(window.location.pathname);
      const clipsInfo = assets.map(({ id, item: { assetId } }) => ({ assetId, id }));

      if (!shouldMoveItemsInsteadOfAdd) {
        await addAssetsToBoardMutation.mutateAsync({
          clipIds,
          parentBoardId: currentBoardId,
          boards: [board],
          trackLocation: 'drag-n-drop',
        });
        return 'added';
      }
      /**
       * If there are discussions on the clips, show the confirmation modal
       * to let the user know that the discussions will be deleted when
       * the clips are moved.
       */
      if (hasComments) {
        showMoveItemsConfirmationModal({
          clips: assets.map(({ item }) => ({
            assetId: item.assetId,
            id: item.id,
            openCommentCount: item.openCommentCount,
          })),
          boards: [],
          selectedBoard: {
            id: board.id,
            title: board.title,
          },
        });
        return false;
      }

      try {
        await moveAssetsToBoardAsync({
          clipsInfo,
          board,
          parentBoardId: currentBoardId!,
          trackLocation: 'drag-n-drop',
        });
        return 'moved';
      } catch (error) {
        showToast(isAirror(error) ? error.message : 'Failed to move assets', {
          color: 'red',
        });
        return false;
      }
    },
    [addAssetsToBoardMutation, moveAssetsToBoardAsync, showMoveItemsConfirmationModal, showToast],
  );

  const handleDragBoardsToBoard = useCallback(
    async ({ boards = [], board }: Pick<HandleDragAssetsToMergeParams, 'boards' | 'board'>) => {
      if (boards.length === 0) return false;

      try {
        await moveBoardsAsync({
          newParentId: board.id,
          boards: boards.map(({ item }) => ({
            ...item,
            ancestors: item.ancestors,
          })),
          library: board.library,
        });
        return true;
      } catch (error) {
        showToast(isAirror(error) ? error.message : 'Failed to move boards', {
          color: 'red',
        });
        return false;
      }
    },
    [moveBoardsAsync, showToast],
  );

  const handleDragItemsToBoard = useCallback(
    async ({ assets = [], boards = [], board, dropLocation }: HandleDragAssetsToMergeParams) => {
      // If a user is in a board and not searching,
      // we want to move the assets to another board, not "add" them
      const shouldMoveItemsInsteadOfAdd = getParentChangeType() === 'move';
      const clipIds = assets?.map(({ id }) => id);
      const boardIds = boards?.map(({ id }) => id);
      const selectedCount = selectedItemsCountSelector(store.getState());
      const hasComments = assets?.some(({ item }) => item.openCommentCount);

      trackCompletedDragging({
        numberOfBoards: boardIds?.length,
        boardIds,
        numberOfAssets: clipIds?.length,
        clipIds,
        dropLocation: {
          type: dropLocation,
          id: board.id,
        },
        usedSelecting: !!selectedCount,
      });

      if (!(hasComments && shouldMoveItemsInsteadOfAdd)) {
        showToast(`Moving ${pluralize('item', assets.length + boards.length, true)}...`);
      }

      if (assets.length > 0 || boards.length > 0) {
        const [boardMoveResult, assetMoveResult] = await Promise.all([
          // Return true if boards were moved, false if not
          handleDragBoardsToBoard({ boards, board }),
          // Return 'moved' if assets were moved, 'added' if not, false if no-op
          handleDragAssetsToBoard({ assets, board, clipIds, hasComments, shouldMoveItemsInsteadOfAdd }),
        ]);

        if (boardMoveResult || assetMoveResult) {
          const onlyAssets = assetMoveResult && !boardMoveResult;
          const onlyBoards = boardMoveResult && !assetMoveResult;

          /**
           * If only assets are moved/added, choose text based on that.
           * If only boards are moved, show text accordingly.
           * If both are truthy, check if assets should be moved or added and formulate text
           * accordingly.
           */
          const toastMessage = onlyAssets
            ? `${pluralize('asset', assets.length, true)} ${assetMoveResult} to `
            : onlyBoards
            ? `${pluralize('board', boards.length, true)} moved to `
            : assetMoveResult === 'moved'
            ? `${pluralize('board', boards.length, true)} & ${pluralize('asset', assets.length, true)} moved to `
            : `${pluralize('board', boards.length, true)} moved & ${pluralize('asset', assets.length, true)} added to `;

          showToast(
            <>
              {toastMessage}
              <ToastLink onClick={() => goToBoardPage({ board, trackLocation: 'add-clips-to-board-toast' })}>
                {board.title}
              </ToastLink>
            </>,
          );
        }
      }

      dispatch(resetSelectedItemsAction());
    },
    [
      dispatch,
      goToBoardPage,
      showToast,
      handleDragAssetsToBoard,
      handleDragBoardsToBoard,
      getParentChangeType,
      trackCompletedDragging,
      store,
    ],
  );

  return {
    handleDragItemsToBoard,
  };
};
