import {
  FileStatusButton,
  FileStatusIconProgressing,
  FileStatusSubtitle,
  FileStatusTitle,
} from '@air/feature-file-status/ui';
import { ChevronDown } from '@air/next-icons';
import { memo } from 'react';

import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { AssetRestorationTask, GetInProgressMetadata } from '~/store/tasks/types';

import { EllipsisInMiddle } from '../EllipsisInMiddle';

export type RestoreAssetTaskInProgressPanelItemProps = {
  metadata: Pick<GetInProgressMetadata<AssetRestorationTask>, 'assets'>;
  onCancel: () => void;
};

export const RestoreAssetTaskInProgressPanelItem = memo(
  ({ onCancel, metadata: { assets } }: RestoreAssetTaskInProgressPanelItemProps) => {
    return (
      <PaneItemTW
        avatar={<FileStatusIconProgressing />}
        title={<FileStatusTitle>Recovering</FileStatusTitle>}
        subtitle={
          <FileStatusSubtitle className="flex text-nowrap">
            {assets.length === 1 ? (
              <>
                <span className="flex-none pr-1">Recovering asset: </span>
                <EllipsisInMiddle className="font-bold" text={assets[0].title || ''} />
              </>
            ) : (
              <span className="truncate">Recovering {assets.length} assets</span>
            )}
          </FileStatusSubtitle>
        }
        buttons={
          <FileStatusButton label="Hide" onClick={onCancel}>
            <ChevronDown className="size-4" />
          </FileStatusButton>
        }
      />
    );
  },
);

RestoreAssetTaskInProgressPanelItem.displayName = 'RestoreAssetTaskInProgressPanelItem';
