import { ClipSource, ImporterListItem } from '@air/api/types';
import { SHARED_PANEL_ITEM_THUMBNAIL_CLASS_NAMES } from '@air/feature-file-status/constants';

import { DropboxImportIcon, GoogleDriveImportIcon, HomeBoxImportIcon } from '~/components/Import/ui';

interface ImportItemIconProps {
  source: ImporterListItem['source'];
}

export const ImportItemIcon = ({ source }: ImportItemIconProps) => {
  switch (source) {
    case ClipSource.dropbox:
      return <DropboxImportIcon isActive={true} className={SHARED_PANEL_ITEM_THUMBNAIL_CLASS_NAMES} />;
    case ClipSource.googleDrive:
      return <GoogleDriveImportIcon isActive={true} className={SHARED_PANEL_ITEM_THUMBNAIL_CLASS_NAMES} />;
    case ClipSource.box:
      return <HomeBoxImportIcon isActive={true} className={SHARED_PANEL_ITEM_THUMBNAIL_CLASS_NAMES} />;
    default:
      return null;
  }
};
