import { FileStatusTrackingPanel, FileStatusTrackingPanelProps, FileTrackingItem } from '@air/feature-file-status/ui';
import {
  useCalculateUploadSpeed,
  useGetUploadHeaderTitles,
  useUploadCancelAlertOnUnload,
  useUploadEstimatedTime,
  useUploadsOfflineListener,
  useWakelock,
} from '@air/feature-uploads/hooks';
import { convertUploadToFileTrackingPaneItem } from '@air/feature-uploads/utils';
import {
  allUploadProgressSelector,
  hasUploadsSelector,
  setUploadsResumedAction,
  uploadArraySelector,
  uploadByIdSelector,
  uploaderIsUploadingSelector,
  UploaderStore,
  uploadingSpeedSelector,
} from '@air/redux-uploader';
import {
  useDeleteAllUploads,
  useDeleteUpload,
  UseDeleteUploadParams,
  usePauseAllUploads,
  usePauseUploadById,
  useResumeAllUploads,
  useRetryAllUploads,
} from '@air/upload-utils';
import { memo, useCallback } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';

export interface UploadsTrackingPanelProps {
  summaryLocation: string | undefined;
  onCancelAllUploads?: () => void;
  onDeleteUpload?: UseDeleteUploadParams['onDelete'];
}

export const UploadsTrackingPanel = memo(
  ({ summaryLocation, onCancelAllUploads, onDeleteUpload }: UploadsTrackingPanelProps) => {
    const dispatch = useDispatch();
    const store = useStore<UploaderStore>();

    useUploadCancelAlertOnUnload();
    useCalculateUploadSpeed();
    const { estimatedTime } = useUploadEstimatedTime();
    const uploadSpeed = useSelector(uploadingSpeedSelector);
    const { isOnline } = useUploadsOfflineListener();

    const hasUploads = useSelector(hasUploadsSelector);

    const isUploading = useSelector(uploaderIsUploadingSelector);
    useWakelock(isUploading);

    const uploads = useSelector(uploadArraySelector);
    const uploadProgress = useSelector(allUploadProgressSelector);
    const uploadsItems = uploads.map((upload) =>
      convertUploadToFileTrackingPaneItem(upload, uploadProgress[upload.id]),
    );

    const { pauseUploadById } = usePauseUploadById();
    const { deleteUpload } = useDeleteUpload({ onDelete: onDeleteUpload });
    const { deleteAllUploads } = useDeleteAllUploads({ onDelete: onDeleteUpload });
    const { pauseAllUploads } = usePauseAllUploads();
    const { resumeAllUploads } = useResumeAllUploads();
    const { retryAllUploads } = useRetryAllUploads();

    const { getUploadHeaderTitles } = useGetUploadHeaderTitles();

    const cancelItem = useCallback(
      (item: FileTrackingItem) => {
        const upload = uploadByIdSelector(store.getState(), item.id);
        if (upload) {
          deleteUpload(upload);
        }
      },
      [deleteUpload, store],
    );

    const pauseItem = useCallback(
      (item: FileTrackingItem) => {
        pauseUploadById(item.id);
      },
      [pauseUploadById],
    );

    const resumeItem = useCallback(
      (item: FileTrackingItem) => {
        dispatch(setUploadsResumedAction({ uploadIds: [item.id] }));
      },
      [dispatch],
    );

    const tryToCancelAll = useCallback(() => {
      if (onCancelAllUploads) {
        onCancelAllUploads();
      } else {
        deleteAllUploads();
      }
    }, [deleteAllUploads, onCancelAllUploads]);

    const getMemoedUploadHeaderTitles: FileStatusTrackingPanelProps['getStatusTitles'] = useCallback(
      (params) =>
        getUploadHeaderTitles({
          estimatedCompletionTime: estimatedTime,
          summaryLocation,
          isOnline,
          speed: uploadSpeed,
        })(params),
      [estimatedTime, getUploadHeaderTitles, isOnline, summaryLocation, uploadSpeed],
    );

    return hasUploads ? (
      <FileStatusTrackingPanel
        testId="UPLOADER_PANE"
        onRetryItem={resumeItem}
        onRetryAll={retryAllUploads}
        onCancelItem={cancelItem}
        onPauseItem={pauseItem}
        onResumeItem={isOnline ? resumeItem : undefined}
        onPauseAll={pauseAllUploads}
        onResumeAll={isOnline ? resumeAllUploads : undefined}
        onCancelAll={tryToCancelAll}
        onDismiss={tryToCancelAll}
        items={uploadsItems}
        getStatusTitles={getMemoedUploadHeaderTitles}
      />
    ) : null;
  },
);

UploadsTrackingPanel.displayName = 'UploadsTrackingPanel';
