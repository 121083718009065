import {
  FileStatusButton,
  FileStatusIconCompleted,
  FileStatusSubtitle,
  FileStatusTitle,
} from '@air/feature-file-status/ui';
import { Close } from '@air/next-icons';
import { memo } from 'react';

import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { AssetRestorationTask, GetCompletedMetadata } from '~/store/tasks/types';

import { EllipsisInMiddle } from '../EllipsisInMiddle';

export type RestoreAssetTaskCompletedPanelItemProps = {
  metadata: GetCompletedMetadata<AssetRestorationTask>;
  onClear: () => void;
};

export const RestoreAssetTaskCompletedPanelItem = memo(
  ({ metadata: { assets }, onClear }: RestoreAssetTaskCompletedPanelItemProps) => {
    return (
      <PaneItemTW
        truncate={false}
        avatar={<FileStatusIconCompleted />}
        title={<FileStatusTitle>Recovered successfully</FileStatusTitle>}
        subtitle={
          <FileStatusSubtitle className="flex text-nowrap">
            {assets.length === 1 ? (
              <>
                <span className="flex-none pr-1">Recovered asset: </span>
                <EllipsisInMiddle className="font-bold" text={assets[0].title || ''} />
              </>
            ) : (
              <span className="truncate">Recovered {assets.length} assets</span>
            )}
          </FileStatusSubtitle>
        }
        buttons={
          <FileStatusButton label="Clear" onClick={onClear}>
            <Close className="size-4" />
          </FileStatusButton>
        }
      />
    );
  },
);

RestoreAssetTaskCompletedPanelItem.displayName = 'RestoreAssetTaskCompletedPanelItem';
