import {
  FileStatusButton,
  FileStatusIconCompleted,
  FileStatusSubtitle,
  FileStatusTitle,
} from '@air/feature-file-status/ui';
import { Close } from '@air/next-icons';
import Link from 'next/link';
import { memo } from 'react';

import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { createBoardRoute } from '~/constants/routes';
import { DuplicateBoardTask, GetCompletedMetadata } from '~/store/tasks/types';

export type DuplicateBoardCompletedPanelItemProps = {
  metadata: GetCompletedMetadata<DuplicateBoardTask>;
  onClear: () => void;
};

export const DuplicateBoardCompletedPanelItem = memo(
  ({ metadata: { sourceBoardName, duplicatedRootBoards }, onClear }: DuplicateBoardCompletedPanelItemProps) => {
    const boardId = duplicatedRootBoards?.[0]?.id;
    const boardTitle = duplicatedRootBoards?.[0]?.title;
    const boardUrl = boardId ? createBoardRoute(boardId, boardTitle) : null;

    return (
      <PaneItemTW
        truncate={false}
        avatar={<FileStatusIconCompleted />}
        title={<FileStatusTitle data-testid="DUPLICATED_SUCESSFULY_TITLE">Duplicated successfully</FileStatusTitle>}
        subtitle={
          <FileStatusSubtitle className="truncate">
            <>
              Board &quot;
              {boardUrl ? (
                <Link className="font-medium underline" href={boardUrl} rel="noopener noreferrer">
                  {boardTitle}
                </Link>
              ) : (
                sourceBoardName
              )}
              &quot; is ready to use.
            </>
          </FileStatusSubtitle>
        }
        buttons={
          <FileStatusButton label="Clear" onClick={() => onClear()}>
            <Close className="size-4" />
          </FileStatusButton>
        }
      />
    );
  },
);

DuplicateBoardCompletedPanelItem.displayName = 'DuplicateBoardCompletedPanelItem';
