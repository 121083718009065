import { FileStatusButton, FileStatusIconErrored, FileStatusTitle } from '@air/feature-file-status/ui';
import { Close } from '@air/next-icons';
import { memo } from 'react';

import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';

export type DeleteLibraryFailedPanelItemProps = {
  onClear: () => void;
};

export const DeleteLibraryFailedPanelItem = memo(({ onClear }: DeleteLibraryFailedPanelItemProps) => {
  return (
    <PaneItemTW
      avatar={<FileStatusIconErrored />}
      title={<FileStatusTitle>Failed to delete library!</FileStatusTitle>}
      buttons={
        <FileStatusButton label="Clear" onClick={onClear}>
          <Close className="size-4" />
        </FileStatusButton>
      }
    />
  );
});

DeleteLibraryFailedPanelItem.displayName = 'DeleteLibraryFailedPanelItem';
