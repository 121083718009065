import { FileStatusButton, FileStatusIconProgressing, FileStatusTitle } from '@air/feature-file-status/ui';
import { ChevronDown } from '@air/next-icons';
import { memo } from 'react';

import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { GetInProgressMetadata, LibraryDeletionTask } from '~/store/tasks/types';

export type DeleteBoardPanelInProgressPanelItemProps = {
  metadata: Pick<GetInProgressMetadata<LibraryDeletionTask>, 'libraryId'>;
  onCancel: () => void;
};

export const DeleteLibraryPanelInProgressPanelItem = memo(({ onCancel }: DeleteBoardPanelInProgressPanelItemProps) => {
  return (
    <PaneItemTW
      avatar={<FileStatusIconProgressing />}
      title={<FileStatusTitle>Deleting library</FileStatusTitle>}
      buttons={
        <FileStatusButton label="Hide" onClick={onCancel}>
          <ChevronDown className="size-4" />
        </FileStatusButton>
      }
    />
  );
});

DeleteLibraryPanelInProgressPanelItem.displayName = 'DeleteLibraryPanelInProgressPanelItem';
