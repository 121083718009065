import {
  FileStatusButton,
  FileStatusIconCompleted,
  FileStatusSubtitle,
  FileStatusTitle,
} from '@air/feature-file-status/ui';
import { Close } from '@air/next-icons';
import { memo } from 'react';

import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { BoardRestorationTask, GetCompletedMetadata } from '~/store/tasks/types';

import { EllipsisInMiddle } from '../EllipsisInMiddle';

export type RestoreBoardCompletedPanelItemProps = {
  metadata: GetCompletedMetadata<BoardRestorationTask>;
  onClear: () => void;
};

export const RestoreBoardCompletedPanelItem = memo(
  ({ metadata: { title }, onClear }: RestoreBoardCompletedPanelItemProps) => {
    return (
      <PaneItemTW
        truncate={false}
        avatar={<FileStatusIconCompleted />}
        title={<FileStatusTitle>Recovered successfully</FileStatusTitle>}
        subtitle={
          <FileStatusSubtitle className="flex text-nowrap">
            <span className="flex-none pr-1">Recovered board: </span>
            <EllipsisInMiddle className="font-bold" text={title || ''} />
          </FileStatusSubtitle>
        }
        buttons={
          <FileStatusButton label="Clear" onClick={onClear}>
            <Close className="size-4" />
          </FileStatusButton>
        }
      />
    );
  },
);

RestoreBoardCompletedPanelItem.displayName = 'RestoreBoardCompletedPanelItem';
